<template>
    <div class="row">
        <div class="col-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul
                    class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                    <li class="list-inline-item" :class="{ 'active': $route.name == 'EventAdmin' }">
                        <router-link :to="{ name: 'EventAdmin' }"
                            class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                            :class="{ 'active': $route.name == 'EventAdmin' }">Event</router-link>
                    </li>
                    <li class="list-inline-item" :class="{ 'active': $route.name == 'EventCategoryAdmin' }">
                        <router-link :to="{ name: 'EventCategoryAdmin' }"
                            class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                            :class="{ 'active': $route.name == 'EventCategoryAdmin' }">Category</router-link>
                    </li>
                    <li class="list-inline-item" :class="{ 'active': $route.name == 'EventTestimonyAdmin' }">
                        <router-link :to="{ name: 'EventTestimonyAdmin' }"
                            class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                            :class="{ 'active': $route.name == 'EventTestimonyAdmin' }">Testimony</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                <div class="row justify-content-center">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <router-link :to="{ name: 'EventTestimonyCreateAdmin' }" class="btn btn-current float-right mt-3"><i
                                class="fas fa-plus"></i> Create New</router-link>
                        <h1 class="mt-4 font-weight-bolder"><i class="fas fa-users mr-3"></i>Feedback</h1>
                        <hr>
                        <div class="row justify-content-center d-flex" v-if="dataExist">
                            <div class="col-xl-12 col-lg-12">
                                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center py-3">
                                <div class="table-responsive mw-100 px-1">
                                    <datatable :class="'table table-hover table-bordered table-sm w-100'" :columns="columns"
                                        :filter="tableFilter" :data="data" :page="1" :perPage="10">
                                        <template name="default" slot-scope="{ row, index }">
                                            <tr v-if="row">
                                                <td class="text-center">{{ index + 1 }}</td>
                                                <td class="text-center">{{ row.name }}</td>
                                                <td class="text-center">
                                                    <div class="text-center">
                                                        <img :src=" row.photo" width="100" class="rounded"
                                                            alt="...">
                                                    </div>
                                                </td>
                                                <td class="text-center">{{ row.email }}</td>
                                                <td class="text-center">{{ row.phone }}</td>
                                                <td class="text-center">{{ row.job }}</td>
                                                <td class="text-center">{{ row.text }}</td>
                                                <td class="text-center">
                                                    <button @click="changeStatusPublishAlert(row.id)" class="btn btn-sm" :class="{ 'btn-success': row.publish, 'btn-danger': !row.publish }"><i class="fas" :class="{ 'fa-check': row.publish, 'fa-times': !row.publish }"></i></button>
                                                </td>
                                                <td class="text-center">
                                                    <router-link :to="{ name: 'EventTestimonyEditAdmin', params: { idTestimony: row.id } }">
                                                        <i class="ti-pencil font-xs text-grey-500 mr-4"></i>
                                                    </router-link>
                                                    <a href="javascript:void(0)" @click="deleteAlert(row.id)">
                                                        <i class="ti-trash font-xs text-grey-500"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr v-if="!dataExist">
                                                <td colspan="8">Nothing to see here</td>
                                            </tr>
                                        </template>
                                    </datatable>
                                </div>
                                <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                                <div class="my-3" v-show="loadData">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                </div>
                                <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                                <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            dataExist: false,
            dataNotExist: false,
            loadData: true,
            media: process.env.VUE_APP_URL_CLOUD,
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                { label: 'No', field: 'id', headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center', filterable: false, sortable: false },
                { label: 'Name', field: 'name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center' },
                { label: 'photo', field: 'photo', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                { label: 'Email', field: 'email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center' },               
                { label: 'Phone', field: 'phone', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center' },
                { label: 'Job', field: 'job', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center' },
                { label: 'Text', field: 'text', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center' },
                { label: 'Publish', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false },
                { label: 'Option', headerClass: 'border-0 bg-current text-white py-3', class: 'product-price-wrapper text-center', filterable: false, sortable: false },
            ]
        }
    },
    created() {
        this.getEventTestimony()
    },
    methods: {
        async getEventTestimony() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/events/testimony`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.loadData = false
                this.dataExist = true
                this.data = res.data.data
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    this.deleteTestimony(id)
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Testimony',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteTestimony(id) {
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/events/testimony`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.getEventTestimony()
            }).catch(() => {
                this.getEventTestimony()
            })
        },
        changeStatusPublishAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Change Status it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then(async (result) => {
                if (result.value) {
                    var data = {
                        _method: 'patch',
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/events/testimony/set-publish`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Testimony',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getEventTestimony()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Testimony',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
}
</script>
